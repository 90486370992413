import React from 'react';
import {useNavigate} from 'react-router-dom';


function Home() {

    const navigate = useNavigate();


    const handleRouteKai = () => {
        navigate("/kai");
    }

    const handleRouteKephi = () => {
        navigate("/kphi");
    }

    return (

        <div className="Game pos-r of-hidden">
            <div className="left pos-r p-b-100">
                <div className="gametab">
                    <h1 className={"playToEarn"}>Play to Earn with Kephi Gallery</h1>

                    <div className="gamesec">

                        <div className="bottom game bor p-b-10 p-t-15 m-b-15">
                            <div className="gameBtns m-t-20">

                                <button className={"btn lo "} onClick={handleRouteKephi}>
                                    Play with
                                    KPHI <img alt=""
                                              className={"txwait ab-r-m m-r-10 "}
                                              src="./img/spin.gif"></img></button>

                                <button className={"btn lo "} onClick={handleRouteKai}> Play with
                                    KAI <img alt=""
                                             className={"txwait ab-r-m m-r-10 "}
                                             src="./img/spin.gif"></img></button>
                            </div>
                        </div>
                        <div className="lnkss">
                            <a rel="noreferrer" className="t-d-none" href="https://docs.kardiainfo.com/info-game"
                               target="_blank"><span className="t-or m-t-10 t-d-none">Learn the rules</span></a>
                            <a rel="noreferrer" className="t-d-none" href="https://kephi.io" target="_blank"><span
                                className="t-or m-t-10 t-d-none">Back to website</span></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

// Wrap and export
export default Home;