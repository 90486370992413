import React from 'react'
import KephiLoader from './kephi_loader.svg';

export default function Loader() {
    return (
        <div className="loader">
            <object aria-label="Loading..." className="ab-c-m loader-svg" type="image/svg+xml" data={KephiLoader}></object>
        </div>
    )
}
