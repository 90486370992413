import './App.css';
import './Utils.css';
import GameTKN from './GameTKN';
import Game from './Game';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Home from "./Home";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={< Home/>}></Route>
                <Route exact path='/kphi' element={< GameTKN/>}></Route>
                <Route exact path='/kai' element={< Game/>}></Route>
            </Routes>
        </Router>
    );
}

export default App;
