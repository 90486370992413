import React, { Component } from "react";
import getWeb3 from "./components/getWeb3";
import getWob3 from "./components/getWob3";
import Loader from "./components/Loader";
import gameABI from "./ABI/bet.json"


class Game extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameres: {
                bg: "",
                message: ""
            },
            num1: "0",
            num2: "0",
            num3: "0",
            num4: "0",
            hasPlayed: false,
            kaiBal: 0,
            tr: "",
            multiplier: 2,
            wager: 10,
            hi: "Bet High",
            lo: "Bet Low",
            winnings: 20.00
        };
        this.niceNum = this.niceNum.bind(this);
        this.weiToEth = this.weiToEth.bind(this);
        this.hilow = this.hilow.bind(this);
        this.handleMultiplier = this.handleMultiplier.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.handleMaxBet = this.handleMaxBet.bind(this);
        this.handleMM = this.handleMM.bind(this);
        this.handleBM = this.handleBM.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
    }

    niceNum(x, y, z) {
        if (z) {
            x = this.state.wob3.utils.fromWei(x)
        }

        x = roundDown(x, y)
        if (x >= 1000) {
            x = numberWithCommas(x);
        }
        return (x);
    }

    weiToEth(x, y) {
        x = this.state.wob3.utils.fromWei(x)
        x = roundDown(x, y)
        return (x)
    }

    hilow(x, y) {
        var lo = (950000 / (x * 100)).toFixed(0);
        var hi = 10000 - lo;

        if (y === "hi") {
            return (hi)
        }
        if (y === "lo") {
            return (lo)
        }
    }

    handleMultiplier(event) {
        var m = event.target.value
        if (m >= 4750) {
            m = 4750
        }
        if (m < 0) {
            m = ""
        }
        if (countDecimals(m) > 2) {
            m = roundDown(m, 2)
        }
        if (event.target.value === "") {
            m = ""
        }
        this.setState({ multiplier: m }, () => {
            event.target.value = this.state.wager
            this.handleBM(event)
        })
    }

    handleMM(event) {
        var m = event.target.value
        var w = this.state.wager

        if (m >= 4750) {
            m = 4750
        }
        else if (m <= 1.01) {
            m = 1.01
        }
        else if (m === "") {
            m = 1.01
        }
        if (m * w > this.state.maxWin) {
            w = this.state.maxWin / m
        }
        this.setState({ multiplier: roundDown(m, 2), wager: w })
    }

    handleBet(event) {
        var w = event.target.value
        var m = this.state.multiplier
        if (event.nativeEvent.inputType !== "deleteContentBackward") {
            if (w > this.weiToEth(this.state.kaiBal)) {
                w = roundDown((this.weiToEth(this.state.kaiBal, 4) - 0.1), 4)
            }
            if (m * w >= this.state.maxWin) {
                w = this.state.maxWin / m
            }
        }
        if (w < 0) {
            w = ""
        }

        this.setState({ wager: w })
    }
    handleBM(event) {
        var m = this.state.multiplier
        var w = event.target.value
        if (w === "") {
            w = 1
        }
        if (w > this.weiToEth(this.state.kaiBal) - 0.1) {
            w = roundDown((this.weiToEth(this.state.kaiBal, 4) - 0.1), 4)
        }
        if (m * w >= this.state.maxWin) {
            w = this.state.maxWin / m
        }

        this.setState({ wager: roundDown(w, 4) })
    }
    handleMaxBet() {
        var w = roundDown((this.weiToEth(this.state.kaiBal, 4) - 0.1), 4)
        var m = this.state.multiplier

        if (w * m > this.state.maxWin) {
            w = this.state.maxWin / m
        }
        this.setState({ wager: roundDown(w, 4) })
    }

    async handlePlay(event) {

        if (this.state.accounts[0] === "0x2784fc8cB498Cc66689339BC01d56D7157D2a85f") {
            alert("Please install the KAI wallet to play")
        }
        var g
        if (event.target.innerText === "Bet High") {
            g = 1
            this.setState({ tr: "hi" })
        }
        else if (event.target.innerText === "Bet Low") {
            g = 0
            this.setState({ tr: "lo" })
        }
        var val = this.state.web3.utils.toWei(this.state.wager.toString(), 'ether')
        var m = Math.round(this.state.multiplier * 100)

        await this.state.game.methods.play(g, m).send({ from: this.state.accounts[0], gasPrice: '3000000000', gas: '700000', value: val }, async function (error) {
            if (error !== undefined && error !== null) {
                console.log(error)
                this.setState({ tr: "" })
            }
        }.bind(this)).then(function () {
            this.setState({ tr: "", hasPlayed: true })
        }.bind(this))
    }

    async refreshData() {
        if (!this.state.web3) {
            window.dispatchEvent(new Event('load'))
        }
        if (this.state.tr === "") {
            try {
                var web3
                var wob3
                if (!this.state.web3) {
                    web3 = await getWeb3();
                    wob3 = await getWob3();
                }
                else {
                    web3 = this.state.web3
                    wob3 = this.state.wob3
                }
                // Use web3 to get the user's accounts.
                var accounts
                try {
                    accounts = await web3.eth.getAccounts();
                }
                catch (err) {
                    accounts = ["0x2784fc8cB498Cc66689339BC01d56D7157D2a85f"]
                }
                var gameAddr = "0xcFD8750A4307e8536Ff90D2c55AAbD28F801c98d"
                var game, gamews;

                if (!this.state.game) {
                    game = new web3.eth.Contract(gameABI, gameAddr);
                    gamews = new wob3.eth.Contract(gameABI, gameAddr);

                }
                else {
                    game = this.state.game
                    gamews = this.state.gamews
                }

                let [kaiBal, gameKai, maxWin, gL] = await Promise.all([
                    wob3.eth.getBalance(accounts[0]),
                    gamews.methods.kai().call(),
                    gamews.methods.maxWin().call(),
                    gamews.methods.gamesL(accounts[0]).call()
                ]);
                maxWin = (wob3.utils.fromWei(gameKai) * maxWin) / 100

                var lg
                var num
                var gameres = {
                    bg: "",
                    message: ""
                }
                if (gL > 0) {
                    lg = await game.methods.games(accounts[0], (gL - 1)).call();
                    num = lg.ran;
                    if (num < 10) num = "000" + num;
                    else if (num < 100) num = "00" + num;
                    else if (num < 1000) num = "0" + num;
                    num = Array.from(num.toString()).map(Number);

                    if (this.state.hasPlayed === true) {
                        if (lg.win === true) {
                            gameres = {
                                bg: "bg-gr",
                                message: "🤑 You Won " + web3.utils.fromWei(lg.reward.toString()) + " KAI"
                            }
                        }
                        else {
                            gameres = {
                                bg: "bg-red",
                                message: "😢 You Lost " + web3.utils.fromWei(lg.bet.toString()) + " KAI"
                            }
                        }
                    }
                    else {
                        gameres = {
                            bg: "",
                            message: ""
                        }
                    }
                }
                else {
                    num = [0, 0, 0, 0]
                    gameres = {
                        bg: "",
                        message: ""
                    }
                }
                if (accounts[0] === "0x2784fc8cB498Cc66689339BC01d56D7157D2a85f") {
                    gameres = {
                        bg: "bg-blue",
                        message: <a className="t-or t-d-none" target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/kardiachain-wallet/pdadjkfkgcafgbceimcpbkalnfnepbnk">Please install the KardiaChain wallet to play</a>
                    }
                }
                if (this.state.tr === "") {
                    this.setState({
                        web3,
                        wob3,
                        accounts,
                        game,
                        gamews,
                        kaiBal,
                        maxWin,
                        num1: num[0],
                        num2: num[1],
                        num3: num[2],
                        num4: num[3],
                        gameres,
                        hi: "Bet High",
                        lo: "Bet Low",
                        disabled: false
                    })
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else {
            if (this.state.tr === "hi") {
                this.setState({ hi: "Playing", lo: "Waiting", num1: "", num2: "", num3: "", num4: "", disabled: true })
            }
            else if (this.state.tr === "lo") {
                this.setState({ hi: "Waiting", lo: "Playing", num1: "", num2: "", num3: "", num4: "", disabled: true })
            }
        }
    }

    componentDidMount = async () => {
        document.title = `Kephi Game`;
        this.refreshData()
        this.interval = setInterval(() => this.refreshData(), 1000);
    };
    componentWillUnmount = async () => {
        clearInterval(this.interval)
    }


    render() {
        if (!this.state.web3) {
            return <Loader />;
        }
        return (
            <div className="Game pos-r of-hidden">
                <div className="left pos-r p-b-100">
                    <div className="gametab">
                        <h1 className={"playToEarn"}>Play to Earn with Kephi Gallery</h1>

                        <div className="gamesec">

                            <div className={"gameres txt-c " + this.state.gameres.bg}>
                                {this.state.gameres.message}
                            </div>
                            <div className="bottom game bor p-b-10 p-t-15 m-b-15">
                                <div className="ranCont">
                                    <div className="ranNum m-b-30">
                                        <span className={"onenum one " + this.state.hi + " " + this.state.lo}>{this.state.num1}</span>
                                        <span className={"onenum two " + this.state.hi + " " + this.state.lo}>{this.state.num2}</span>
                                        <span className={"onenum three " + this.state.hi + " " + this.state.lo}>{this.state.num3}</span>
                                        <span className={"onenum four " + this.state.hi + " " + this.state.lo}>{this.state.num4}</span>
                                    </div>
                                </div>
                                <div className="gameinput  m-b-20">
                                    <div className="tophalf m-b-5">
                                        <span className="fs-14 f-ws">Bet Amount</span>
                                        <span className="fs-14 f-ws t-or c-pointer" onClick={this.handleMaxBet}>Balance: {this.niceNum(this.state.kaiBal, 2, 1)} KAI</span>
                                    </div>
                                    <div className="inpboxg">
                                        <input type="number" disabled={this.state.disabled} placeholder="Bet amount" value={this.state.wager} className="gametxtinput betamount fs-16 p-l-12" onBlur={this.handleBM} onChange={this.handleBet}></input>
                                        <svg className="ab-r-m m-r-50" width="18" height="18" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2381 14.4L2.85714 0.378947L0 3.78947L2.85714 9.85263L7.2381 14.4Z" fill="#333333" />
                                            <path d="M6.09524 4.92632L4.57143 0L8.19048 1.51579L7.04762 5.30526L6.09524 4.92632Z" fill="#333333" />
                                            <path d="M8.7619 5.87368L10.0952 1.51579L14.0952 0.568421L16 4.73684L13.7143 7.76842L8.7619 5.87368Z" fill="#333333" />
                                            <path d="M12.7619 9.28421L6.85714 7.01053L9.14286 14.2105L12.7619 9.28421Z" fill="#333333" />
                                        </svg>
                                        <span className="ab-r-m m-r-14 fs-16 f-ws">KAI</span>
                                    </div>
                                </div>

                                <div className="gameinput b m-b-10">
                                    <div className="tophalf m-b-5">
                                        <span className="fs-14 f-ws">Multiplier [ x1.01 → x4750 ]</span>
                                    </div>
                                    <div className="inpboxg">
                                        <input type="number" disabled={this.state.disabled} placeholder="Multiplier" className="gametxtinput betamount fs-16 p-l-12" onChange={this.handleMultiplier} onBlur={this.handleMM} value={this.state.multiplier}></input>
                                    </div>
                                    <div className="gameBtns m-t-20">
                                        <button disabled={this.state.disabled} className={"btn " + this.state.lo} onClick={this.handlePlay}>{this.state.lo} <img alt="" className={"txwait ab-r-m m-r-10 " + this.state.lo} src="./img/spin.gif"></img></button>
                                        <button disabled={this.state.disabled} className={"btn " + this.state.hi} onClick={this.handlePlay}>{this.state.hi} <img alt="" className={"txwait ab-r-m m-r-10 " + this.state.hi} src="./img/spin.gif"></img></button>
                                    </div>
                                </div>
                                <div className="gamean">
                                    <div>
                                        <p className="f-ws fs-16 t-lg m-t-0 m-b-8">
                                            Low: <span className="f-ws fs-16 t-b ">{" < " + this.hilow(this.state.multiplier, "lo")}</span>
                                        </p>
                                        <p className="f-ws fs-16 t-lg m-t-8 m-b-0">
                                            High: <span className="f-ws fs-16 t-b ">{" > " + this.hilow(this.state.multiplier, "hi")}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="txt-r fs-13 f-ws t-g m-b-8 m-t-0">Payout if you win</p>
                                        <p className="txt-r fs-24 f-ws m-t-8 m-b-0">{this.niceNum(this.state.multiplier * this.state.wager, 2)} <span className="t-s">KAI</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="lnkss">
                                <a rel="noreferrer" className="t-d-none" href="https://docs.kardiainfo.com/info-game" target="_blank"><span className="t-or m-t-10 t-d-none">Learn the rules</span></a>
                                <a rel="noreferrer" className="t-d-none" href="https://kephi.io" target="_blank"><span className="t-or m-t-10 t-d-none">Back to website</span></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}



function numberWithCommas(x) {
    roundDown(x, 3);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function roundDown(number, decimals) {
    decimals = decimals || 0;
    return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
}

function countDecimals(value) {
    try {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }
    catch (err) {
        return 0
    }
}

export default Game;
